.container h1 {
  font-size: theme('fontSize.2xl');
  font-weight: theme('fontWeight.bold');
  color: theme('colors.gray.900');
}

.container h2 {
  font-size: theme('fontSize.xl');
  font-weight: theme('fontWeight.bold');
  color: theme('colors.gray.900');
}

.container h3 {
  font-size: theme('fontSize.lg');
  font-weight: theme('fontWeight.bold');
  color: theme('colors.gray.900');
}

.container p {
  font-size: theme('fontSize.sm');
  font-weight: theme('fontWeight.normal');
  color: theme('colors.gray.600');
}

.container a {
  font-size: theme('fontSize.sm');
  font-weight: theme('fontWeight.normal');
  color: theme('colors.indigo.500');
}

.container ol {
  list-style-type: decimal;
  padding-left: theme('padding.6');
  font-size: theme('fontSize.sm');
  font-weight: theme('fontWeight.normal');
  color: theme('colors.gray.600');
}

.container ul {
  list-style-type: disc;
  padding-left: theme('padding.6');
  font-size: theme('fontSize.sm');
  font-weight: theme('fontWeight.normal');
  color: theme('colors.gray.600');
}
