.container h1 {
  font-size: theme('fontSize.2xl');
  font-weight: theme('fontWeight.bold');
  margin-bottom: 0.25em;
}

.container h2 {
  font-size: theme('fontSize.xl');
  font-weight: theme('fontWeight.bold');
  margin-bottom: 0.25em;
}

.container h3 {
  font-size: theme('fontSize.lg');
  font-weight: theme('fontWeight.bold');
}

.container p {
  margin-bottom: 0.25em;
}

.container a {
  color: theme('colors.indigo.500');
}

.container ol {
  list-style-type: decimal;
  padding-left: theme('padding.6');
}

.container ul {
  list-style-type: disc;
  padding-left: theme('padding.7');
}
